import { Client, Functions} from 'appwrite'

export const client = new Client()

client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('65eb577e452dfa63a8c9')

export const functions = new Functions(client)
// export { ID } from 'appwrite'
