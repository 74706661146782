<template>
  <div class="flex" v-if="!loading">
    <div class="flex flex-col w-full gap-6">
      <client-only>
        <div class="relative">
          <VDatePicker
            v-model.range="dates"
            timezone="Europe/Rome"
            mode="date"
            first-day-of-week="2"
            borderless
            expanded
            :min-date="minDate"
            :max-date="maxDate"
            transparent
            @dayclick="dayclick"
          >
            <template #day-content="dayContext">
              <DayDisplay :day-context="dayContext" :disabled-dates="disabledDates" />
            </template>
          </VDatePicker>
          <div class="absolute bottom-0 inset-x-0 top-12" style="z-index: 9" />
        </div>
      </client-only>
    </div>
  </div>
  <VaSkeletonGroup v-else>
    <div class="flex">
      <div class="flex flex-col w-full items-center gap-6">
        <VaSkeleton width="250px" height="270px" variant="rounded" />
      </div>
    </div>
  </VaSkeletonGroup>
</template>

<script setup>
import { ref, computed, defineProps } from "vue"
import { functions } from "./lib/appwrite.js"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
dayjs.extend(isSameOrAfter)
import DayDisplay from "/src/components/DayDisplay.vue"

const props = defineProps({
  roomId: String,
})

const loading = ref(true)
const roomId = ref(props.roomId)
const roomData = ref({})
const dates = ref({ start: null, end: null })

const disabledDates = computed(() => {
  const dates = roomData.value.disabled_dates.filter(
    (date) =>
      dayjs(date.start).isSameOrAfter(dayjs(), "day") ||
      dayjs(date.end).isAfter(dayjs(), "day")
  )
  return dates || []
})

const maxDate = computed(() => {
  const { end } = roomData.value
  if (!end) return null
  const close = dayjs(end).add(1, "day")
  return close.toDate()
})

const minDate = computed(() => {
  const { start } = roomData.value
  if (!start) return dayjs().add(1, "day").toDate()
  const open = dayjs(start)
  const today = dayjs()
  return open < today ? today.toDate() : open.toDate()
})

functions
  .createExecution(
    "get-public-room-data", // Replace with your function ID
    "", // Request body data
    false, // Async
    "/?roomId=" + roomId.value, // Path
    "GET" // Set the HTTP method to 'GET'
  )
  .then(({ responseBody }) => {
    roomData.value = JSON.parse(responseBody)
  })
  .catch((error) => console.error(error))
  .finally(() => (loading.value = false))
</script>

<style>
:root {
  --vc-font-family: "Roboto", BlinkMacSystemFont, -apple-system, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}
</style>
