import { createApp } from 'vue';
import App from './App.vue';
import {
  createVuesticEssential,
  VaButton,
  VaInnerLoading,
  VaSkeleton,
  VaSkeletonGroup,
} from "vuestic-ui";
import { setupCalendar, DatePicker } from 'v-calendar';
// import 'v-calendar/style.css'

// Import styles
// import "vuestic-ui/styles/reset.css";
import "./reset.css";
import "./vcalendar.css";
import "./main.css";
import "vuestic-ui/styles/essential.css";
// import "vuestic-ui/styles/typography.css";
import "vuestic-ui/styles/smart-helpers.css";

function mountVueApp(el) {
  const props = {
    roomId: el.getAttribute('data-room-id') // Retrieve room ID from the element
  };

  const app = createApp(App, props);

  // .use(setupCalendar, {})
  app.use(createVuesticEssential({
    components: {
      VaButton,
      VaInnerLoading,
      VaSkeleton,
      VaSkeletonGroup,
    },
    config: {},
  }));

  app.use(setupCalendar, {})
  app.component('VDatePicker', DatePicker)

  app.mount(el);
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.booking-button').forEach(mountVueApp);
});
