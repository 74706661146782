<template>
  <div v-on="props.dayContext.dayEvents" class="day-container">
    <div v-if="isCheckin && !isCheckout" class="checkin" />
    <div v-if="isCheckout && !isCheckin" class="checkout" />
    <div
      class="day-text"
      :class="{ disabled: isDisabled, before: isBefore }"
      v-text="props.dayContext.day.day"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(isBetween)

const props = defineProps({
  dayContext: {
    type: Object,
    required: true,
  },
  disabledDates: {
    type: Array,
    required: true,
  },
})

const isCheckin = computed(() => {
  const date = dayjs(props.dayContext.day.date)
  const startDates = props.disabledDates.map((date) => date.start)
  return startDates.some((startDate) => date.isSame(startDate, "day"))
})

const isCheckout = computed(() => {
  const date = dayjs(props.dayContext.day.date)
  const endDates = props.disabledDates.map((date) => date.end)
  return endDates.some((endDate) => date.isSame(endDate, "day"))
})

const isDisabled = computed(() => {
  const date = dayjs(props.dayContext.day.date)
  return (
    props.disabledDates.some((disabledDate) =>
      date.isBetween(disabledDate.start, disabledDate.end, "day", "()")
    ) ||
    (isCheckin.value && isCheckout.value)
  )
})

const isToday = computed(() => {
  const date = dayjs(props.dayContext.day.date)
  return date.isSame(dayjs(), "day")
})

const isBefore = computed(
  () =>
    dayjs(props.dayContext.day.date).isBefore(dayjs(), "day") ||
    (isToday.value && isCheckin.value)
)
</script>

<style>
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.bg-disabled {
  @apply bg-slate-300;
}

.text-disabled {
  @apply text-slate-600;
}

.before {
  @apply text-slate-400 roboto-regular;
}

/* .before {
  @apply text-slate-400 font-normal;
} */

.border-custom {
  @apply border-solid border-slate-400;
}

.center {
  @apply flex justify-center items-center;
}

.day-container {
  @apply center w-full -z-10 h-full relative select-none roboto-bold;
}

/* .day-container {
  @apply libre-baskerville-regular center w-full -z-10 h-full relative select-none font-semibold;
} */

.disabled {
  @apply text-disabled bg-disabled roboto-regular;
}

.day-text {
  @apply center absolute inset-y-1 inset-x-0 z-10;
}

.decoration {
  @apply absolute inset-y-1 w-1/6 bg-disabled border-custom;
}

.checkin {
  @apply decoration right-0 rounded-l-full border-l-4;
}

.checkout {
  @apply decoration left-0 rounded-r-full border-r-4;
}

/* .today {
  @apply absolute inset-y-1 aspect-square rounded-full bg-slate-300;
} */
</style>
